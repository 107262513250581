import L from "leaflet";
import "leaflet/dist/leaflet.css";

const New = ({ divId, mapApiKey }) => {
    document.getElementById(divId).style.height = "100%";
    var map = L.map(divId, {
        tap: false,
        minZoom: 11,
        scrollWheelZoom: true,
        // Prevent the map from moving out of HK
        maxBounds: L.latLngBounds(
            L.latLng(22.172847, 113.82326),
            L.latLng(22.601618, 114.427596)
        )
    }).setView([22.37675, 114.14246], 11);

    L.tileLayer(
        "https://api.hkmapservice.gov.hk/osm/xyz/basemap/WGS84/tile/{z}/{x}/{y}.png?key=" +
            mapApiKey,
        {
            attribution:
                "<a href='https://api.portal.hkmapservice.gov.hk/disclaimer' target='_blank'>&copy; Map from Lands Department <img src='https://api.hkmapservice.gov.hk/mapapi/landsdlogo.jpg' style='width:25px;height:25px' aria-label='Lands Department'/></a>",
            maxZoom: 19,
            id: "APIKEY"
        }
    ).addTo(map);
    L.tileLayer(
        `https://api.hkmapservice.gov.hk/osm/xyz/label-en/WGS84/tile/{z}/{x}/{y}.png?key=${mapApiKey}`,
        {
            maxZoom: 19,
            id: "APIKEY"
        }
    ).addTo(map);
    map.layerGroup = L.layerGroup().addTo(map);
    return map;
};

export default {
    New
};
