const sortings = [
    { name: "Lamppost", value: "LAMPPOST" },
    { name: "Month", value: "MONTH" },
    { name: "Last Updated By", value: "LAST_UPDATED_BY" },
    { name: "Last Updated Time", value: "LAST_UPDATED_AT" },
    { name: "Status", value: "STATUS" }
];

const statusDict = {
    RAW: "RAW",
    INTERIM: "INTERIM",
    APPROVING: "APPROVING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED"
};

const { RAW, INTERIM, APPROVING, APPROVED, REJECTED } = statusDict;

const statusList = [RAW, INTERIM, APPROVING, APPROVED, REJECTED];

const lamppostUtil = {
    sortings,
    statusList,
    statusDict
};

export default lamppostUtil;

export const lamppostDisplayName = (lamppost) =>
    lamppost ? lamppost.location_tc + " (" + lamppost.location_en + ")" : "";
