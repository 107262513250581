import React, { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import L from "leaflet";

import Layout from "../components/layout";
import Icon, {
    ZoomIn,
    PencilSquare,
    Table,
    GeoAltFill,
    PlayFill,
    PauseFill,
    StopFill
} from "../components/icon";
import Loading from "../components/loading";

import { CurrentUserContext } from "../providers/auth";
import Services, { isUnauthorizedError } from "../services";
import { lamppostDisplayName } from "../utils/lamppost";
import Links from "../utils/links";
import { formatDate, timeFormats } from "../utils/time";
import Map from "../utils/map";
import { useSettings } from "../hooks";

const LatestPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.Latest}
            seoTitle="Latest data"
            mainDivCss="px-4 pt-3 lg:px-8"
            forceCheckSessionCount={checkCount}
        >
            <RollingAverages forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default LatestPage;

const RollingAverages = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const lamppostService = Services(currentUser).lamppost;
    const refreshRate = 60000; // 1 minute
    const refreshRateGrf = "1m"; // 1 minute
    const { grfPanels } = useSettings();

    const {
        avg1Min,
        avg5Min,
        avg10Min,
        avg1Hour,
        avg1Day,
        avg1Week,
        avg1Month
    } = {
        avg1Min: "1-min",
        avg5Min: "5-min",
        avg10Min: "10-min",
        avg1Hour: "1-hour",
        avg1Day: "1-day",
        avg1Week: "1-week",
        avg1Month: "1-month"
    };
    const avgIntervals = [
        avg1Min,
        avg5Min,
        avg10Min,
        avg1Hour,
        avg1Day,
        avg1Week,
        avg1Month
    ];

    const emptyAvg = { records: [] };

    const [lamppostsAvg, setLamppostsAvg] = useState(emptyAvg);
    const [avgInterval, setAvgInterval] = useState(avgIntervals[0]);
    const [lamppost, setLamppost] = useState(null);
    const [lamppostDict, setLamppostDict] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [delay, setDelay] = useState(1);
    const [showEditDelay, setShowEditDelay] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [refreshSchedule, setRefreshSchedule] = useState(null);
    const [playingTS, setPlayingTS] = useState(false);
    const [lamppostAvgTS, setLamppostAvgTS] = useState([]);
    const [tsIdx, setTSIdx] = useState(0);
    const [playSchedule, setPlaySchedule] = useState(null);

    const avgIntervalRef = useRef(avgIntervals[0]);
    const delayRef = useRef(1);
    const playingTSRef = useRef(false);

    const calculateExtraDelay = () => {
        // calculate number of minute shall be delayed for keeping current time series range
        const extraDelay =
            playingTS && lamppostsAvg.max_observation_time
                ? parseInt(
                      (new Date().getTime() -
                          new Date(
                              lamppostsAvg.max_observation_time
                          ).getTime()) /
                          60000
                  ) - delay
                : 0;
        return extraDelay > 0 ? extraDelay : 0;
    };

    const getGrfPanelUrl = (templateUrl, from, to = "now") => {
        const extraDelay = calculateExtraDelay();
        const toDelay = +delay + extraDelay - 1;
        return templateUrl
            .replace("<FROM>", encodeURIComponent(from))
            .replace(
                "<TO>",
                encodeURIComponent(to + (toDelay > 0 ? `-${toDelay}m` : ""))
            )
            .replace(
                "<REFRESH_RATE>",
                encodeURIComponent(playingTS ? "" : refreshRateGrf)
            )
            .replace("<DELAY>", encodeURIComponent(+delay + extraDelay))
            .replace("<LAMPPOST>", encodeURIComponent(lamppost.location_tc));
    };

    // Grafana time range: h = hour, m = minute, d = day, w = week
    // From time is the time of 1st tick = date_trunc('minute', now - <Delay in min>) - <Period of total ticks> + <Interval of tick>
    // In Grafana, '+1m' is added to 'From time' as offset for min average, '+2m' is added as offset for non-min average to compensate date_trunc
    const rollingAvgUrls = (() => {
        let urls = {};
        urls[avg1Min] = () =>
            getGrfPanelUrl(
                grfPanels.movingAvg1Min,
                `now-1h-${+delay + calculateExtraDelay()}m`
            );
        urls[avg5Min] = () =>
            getGrfPanelUrl(
                grfPanels.movingAvg5Min,
                `now-6h+5m-${+delay + calculateExtraDelay() + 1}m`
            );
        urls[avg10Min] = () =>
            getGrfPanelUrl(
                grfPanels.movingAvg10Min,
                `now-12h+10m-${+delay + calculateExtraDelay() + 1}m`
            );
        urls[avg1Hour] = () =>
            // now - 47h = now - 2d + 1h
            getGrfPanelUrl(
                grfPanels.movingAvg1Hour,
                `now-47h-${+delay + calculateExtraDelay() + 2}m`
            );
        urls[avg1Day] = () =>
            // now - 13d = now - 2w + 1d
            getGrfPanelUrl(
                grfPanels.movingAvg1Day,
                `now-13d-${+delay + calculateExtraDelay() + 2}m`
            );
        urls[avg1Week] = () =>
            getGrfPanelUrl(
                grfPanels.movingAvg1Week,
                `now-23w-${+delay + calculateExtraDelay() + 2}m`
            );
        urls[avg1Month] = () =>
            getGrfPanelUrl(
                grfPanels.movingAvg1Month,
                `now-510d-${+delay + calculateExtraDelay() + 2}m`
            );
        return urls;
    })();

    const handleAuthError = (err) => {
        if (isUnauthorizedError(err)) {
            forceCheckSession();
        }
    };

    const updateAvgInterval = (e) => {
        const newAvgInterval = e.target.value;
        setAvgInterval(newAvgInterval);
        setUpdating(true);
        lamppostService
            .getLamppostsMovingAvg(newAvgInterval, delay)
            .then((d) => {
                if (!playingTSRef.current) {
                    setLamppostsAvg(d);
                }
            })
            .catch((err) => {
                handleAuthError(err);
                if (!playingTSRef.current) {
                    setLamppostsAvg(emptyAvg);
                }
            })
            .finally(() => setUpdating(false));
    };

    const updateDelay = (e) => {
        const newDelay = e.target.value;
        setDelay(newDelay);
        setShowEditDelay(false);
        setUpdating(true);
        lamppostService
            .getLamppostsMovingAvg(avgInterval, newDelay)
            .then((d) => {
                if (!playingTSRef.current) {
                    setLamppostsAvg(d);
                }
            })
            .catch((err) => {
                handleAuthError(err);
                if (!playingTSRef.current) {
                    setLamppostsAvg(emptyAvg);
                }
            })
            .finally(() => setUpdating(false));
    };

    const scheduleRefresh = () => {
        let timeOffset = new Date().getSeconds();
        let refreshTime = refreshRate / 1000 - timeOffset;
        setRefreshSchedule({
            time: new Date(),
            refreshTime
        });
    };

    const loadLatestMovingAvgPromise = () =>
        lamppostService
            .getLamppostsMovingAvg(avgIntervalRef.current, delayRef.current)
            .then((d) => {
                if (!playingTSRef.current) {
                    setLamppostsAvg(d);
                }
            })
            .catch((err) => {
                handleAuthError(err);
                if (!playingTSRef.current) {
                    setLamppostsAvg(emptyAvg);
                }
            })
            .then(() => {
                if (!playingTSRef.current) {
                    scheduleRefresh();
                }
            });

    const playNextTick = () => {
        setTSIdx((prev) => {
            if (prev + 1 >= lamppostAvgTS.length) {
                return 0;
            }
            return prev + 1;
        });
    };

    const startPlayingTS = () => {
        if (!lamppostsAvg.max_observation_time) {
            return;
        }
        setPlayingTS(true);
        setShowEditDelay(false);
        setRefreshSchedule(null);
        lamppostService
            .getLamppostMovingAvgTimeSeries(
                avgInterval,
                lamppostsAvg.max_observation_time
            )
            .then((d) => {
                setLamppostAvgTS(d.timeseries);
                setTSIdx(0);
                if (playingTSRef.current) {
                    setPlaySchedule(new Date());
                }
            })
            .catch((err) => {
                handleAuthError(err);
                if (playingTSRef.current) {
                    setLamppostAvgTS([]);
                    setPlaySchedule(null);
                }
            });
    };

    const pausePlayingTS = () => {
        setPlaySchedule(null);
    };

    const resumePlayingTS = () => {
        playNextTick();
        if (playingTS) {
            setPlaySchedule(new Date());
        }
    };
    const stopPlayingTS = () => {
        setPlaySchedule(null);
        setPlayingTS(false);
        setUpdating(true);
        setLamppostAvgTS([]);
        loadLatestMovingAvgPromise().then(() => setUpdating(false));
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setUpdating(true);
        Promise.all([
            loadLatestMovingAvgPromise(),
            lamppostService
                .getLampposts()
                .then((d) => {
                    setLamppostDict(
                        d.lampposts.reduce((dict, l) => {
                            dict[l.lamppost_id] = l;
                            return dict;
                        }, {})
                    );
                })
                .catch((err) => {
                    handleAuthError(err);
                    setLamppostDict({});
                })
        ]).then(() => setUpdating(false));
    }, [currentUser]);

    useEffect(() => {
        let refreshAvg = null;
        if (refreshSchedule && refreshSchedule.refreshTime > 0) {
            refreshAvg = setTimeout(() => {
                setUpdating(true);
                loadLatestMovingAvgPromise().then(() => setUpdating(false));
            }, refreshSchedule.refreshTime * 1000);
        }
        return () => refreshAvg && clearTimeout(refreshAvg);
    }, [refreshSchedule]);

    useEffect(() => {
        let playNext = null;
        if (playSchedule) {
            playNext = setInterval(() => {
                playNextTick();
            }, 1000);
        }
        return () => playNext && clearInterval(playNext);
    }, [playSchedule]);

    useEffect(() => {
        // keep average interval ref updated
        avgIntervalRef.current = avgInterval;
    }, [avgInterval]);

    useEffect(() => {
        // keep delay ref updated
        delayRef.current = delay;
    }, [delay]);

    useEffect(() => {
        // keep playing time series ref updated
        playingTSRef.current = playingTS;
    }, [playingTS]);

    const latestMovingAvg = () => (
        <table className="w-full admin-table">
            <thead>
                <tr>
                    <th style={{ width: "55%" }}>Lamppost</th>
                    <th style={{ width: "15%" }}>Avg NO (ppb)</th>
                    <th style={{ width: "15%" }}>Avg NO2 (ppb)</th>
                    <th style={{ width: "15%" }}>Avg PM2.5 (μg/m3)</th>
                </tr>
            </thead>
            <tbody>
                {lamppostsAvg.records.map((l, idx) => (
                    <tr key={idx}>
                        <td>
                            <button
                                className="btn-link w-full text-left"
                                onClick={() => {
                                    setLamppost(lamppostDict[l.lamppost_id]);
                                    setShowPopup(true);
                                }}
                                title="View rolling averages"
                            >
                                <Icon
                                    name={ZoomIn}
                                    width="16px"
                                    cssClass="inline-block mr-2 -ml-2"
                                />
                                {lamppostDisplayName(
                                    lamppostDict[l.lamppost_id]
                                )}
                            </button>
                        </td>
                        <td>{l.no !== null && l.no.toFixed(1)}</td>
                        <td>{l.no2 !== null && l.no2.toFixed(1)}</td>
                        <td>{l.pm25 !== null && l.pm25.toFixed(1)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const dataDelayOption = () => (
        <>
            <span className="label">Delay:</span>
            {showEditDelay ? (
                <select
                    className="input-inline mr-12"
                    style={{ width: "80px" }}
                    value={delay}
                    onChange={updateDelay}
                    disabled={updating || playingTS}
                >
                    {[1, 2, 3, 4, 5].map((min, idx) => {
                        return (
                            <option key={idx} value={min}>
                                {min} {min > 1 ? "mins" : "min"}
                            </option>
                        );
                    })}
                </select>
            ) : (
                <>
                    {delay} {delay > 1 ? "mins" : "min"}
                    <button
                        className="btn-link mr-12"
                        title="Change delay minutes"
                        onClick={() => setShowEditDelay(true)}
                        disabled={updating || playingTS}
                    >
                        <Icon
                            name={PencilSquare}
                            width="16px"
                            cssClass="inline-block"
                        />
                    </button>
                </>
            )}
        </>
    );

    const displayOption = () => (
        <div className="float-right">
            {showMap ? (
                <button
                    className="toggle-item"
                    title="Display in table format"
                    onClick={() => setShowMap(false)}
                    disabled={updating || playingTS}
                >
                    <Icon name={Table} width="16px" cssClass="inline-block" />
                </button>
            ) : (
                <div className="toggle-active">
                    <Icon name={Table} width="16px" cssClass="inline-block" />
                </div>
            )}
            {!showMap ? (
                <button
                    className="toggle-item"
                    title="Display in mapped format"
                    onClick={() => setShowMap(true)}
                    disabled={updating || playingTS}
                >
                    <Icon
                        name={GeoAltFill}
                        width="16px"
                        cssClass="inline-block"
                    />
                </button>
            ) : (
                <div className="toggle-active">
                    <Icon
                        name={GeoAltFill}
                        width="16px"
                        cssClass="inline-block"
                    />
                </div>
            )}
        </div>
    );

    const lamppostTimeSeries = () => (
        <div className="lg:ml-20 relative">
            <div className="absolute w-full border bg-white z-1050 shadow-xl p-4 rounded-lg">
                <iframe
                    className="border-0"
                    src={
                        rollingAvgUrls[avgInterval]
                            ? rollingAvgUrls[avgInterval]()
                            : ""
                    }
                    width="100%"
                    height="400"
                ></iframe>
                <div className="text-center">
                    <button
                        className="w-1/3 btn-secondary"
                        onClick={() => {
                            setShowPopup(false);
                            setShowEditDelay(false);
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="title-h1">Latest Data - Rolling Average</div>
            <div className="mb-3">
                <span className="label">Rolling Average:</span>
                <select
                    className="input-inline px-4 mr-12"
                    style={{ width: "180px" }}
                    value={avgInterval}
                    onChange={updateAvgInterval}
                    disabled={updating || playingTS}
                >
                    {avgIntervals.map((interval, idx) => {
                        return (
                            <option key={idx} value={interval}>
                                {interval +
                                    (interval === avg1Month
                                        ? " (30 days)"
                                        : "")}
                            </option>
                        );
                    })}
                </select>
                {dataDelayOption()}
                {playingTS ? (
                    <>
                        <span className="label">Time Range:</span>
                        {lamppostAvgTS[0]?.max_observation_time ? (
                            formatDate(
                                lamppostAvgTS[0].max_observation_time,
                                timeFormats.dayWithMin
                            )
                        ) : (
                            <Loading
                                cssClass="inline-block text-gray-700"
                                word=""
                            />
                        )}
                        &nbsp;-&nbsp;
                        {lamppostAvgTS[lamppostAvgTS.length - 1]
                            ?.max_observation_time ? (
                            formatDate(
                                lamppostAvgTS[lamppostAvgTS.length - 1]
                                    .max_observation_time,
                                timeFormats.dayWithMin
                            )
                        ) : (
                            <Loading
                                cssClass="inline-block text-gray-700"
                                word=""
                            />
                        )}
                    </>
                ) : (
                    <>
                        <span className="label">Latest Time:</span>
                        {updating ? (
                            <Loading
                                cssClass="inline-block text-gray-700"
                                word=""
                            />
                        ) : (
                            <>
                                {lamppostsAvg.max_observation_time &&
                                    formatDate(
                                        lamppostsAvg.max_observation_time,
                                        timeFormats.dayWithMin
                                    )}
                            </>
                        )}
                    </>
                )}
                {displayOption()}
            </div>
            {showPopup && lamppost && lamppostTimeSeries()}
            {lamppostsAvg.records.length !== 0 &&
                (showMap ? (
                    <RollingAverageMap
                        rollingAverages={
                            playingTS
                                ? lamppostAvgTS.length !== 0
                                    ? lamppostAvgTS[tsIdx]?.records || []
                                    : []
                                : lamppostsAvg.records
                        }
                        lamppostDict={lamppostDict}
                        onLamppostClick={(l) => {
                            setLamppost(l);
                            setShowPopup(true);
                        }}
                        onStartPlaying={startPlayingTS}
                        onPausePlaying={pausePlayingTS}
                        onResumePlaying={resumePlayingTS}
                        onStopPlaying={stopPlayingTS}
                        dataTime={
                            playingTS
                                ? lamppostAvgTS[tsIdx]?.max_observation_time
                                : lamppostsAvg.max_observation_time
                        }
                        disabled={updating}
                    />
                ) : (
                    latestMovingAvg()
                ))}
        </>
    );
};

RollingAverages.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const POLLUTANTS = {
    no: "Avg NO (ppb)",
    no2: "Avg NO2 (ppb)",
    pm25: "Avg PM2.5 (μg/m3)"
};

const toFixed = (val) =>
    typeof val !== "undefined" && val !== null ? val.toFixed(1) : "-";

const RollingAverageMap = ({
    rollingAverages,
    lamppostDict,
    onLamppostClick,
    onStartPlaying,
    onPausePlaying,
    onResumePlaying,
    onStopPlaying,
    dataTime,
    disabled
}) => {
    const { MAP_API_KEY } = useSettings();
    const mapRef = useRef(null);

    const [map, setMap] = useState(null);
    const [pollutant, setPollutant] = useState(
        Object.keys(POLLUTANTS)[0] || ""
    );
    const [isBoundFit, setIsBoundFit] = useState(false);
    const [isPlayingMode, setIsPlayingMode] = useState(false);
    const [paused, setPaused] = useState(false);

    useEffect(() => {
        if (!map && MAP_API_KEY) {
            setMap(Map.New({ divId: "mapid", mapApiKey: MAP_API_KEY }));
        }
    }, [map, MAP_API_KEY]);

    useEffect(() => {
        if (
            !map ||
            rollingAverages.length === 0 ||
            Object.keys(lamppostDict).length === 0 ||
            pollutant === ""
        ) {
            return;
        }

        map.layerGroup.clearLayers();

        rollingAverages.forEach((d) => {
            let marker = L.circleMarker(
                [
                    lamppostDict[d.lamppost_id].latitude,
                    lamppostDict[d.lamppost_id].longitude
                ],
                {
                    radius: 5,
                    color: "#1f78c7",
                    fillOpacity: 1
                }
            ).addTo(map.layerGroup);
            if (d[pollutant]) {
                marker.bindTooltip(toFixed(d[pollutant]), {
                    direction: "top",
                    permanent: true,
                    opacity: 1
                });
            }
            marker.bindPopup(
                `
<div class="lf-popup" id="popup-${d.lamppost_id.toLowerCase()}" tabindex="0">
    <div class="lamppost">${lamppostDisplayName(
        lamppostDict[d.lamppost_id]
    )}</div>
    <table class="admin-table">
    <tbody>
        <tr>
            <th>Avg NO (ppb)</th>
            <td class="value">${toFixed(d.no)}</td>
        </tr>
        <tr>
            <th>Avg NO2 (ppb)</th>
            <td class="value">${toFixed(d.no2)}</td>
        </tr>
        <tr>
            <th>Avg PM2.5 (μg/m3)</th>

            <td class="value">${toFixed(d.pm25)}</td>
        </tr>
    </tbody>
    </table>
</div>
                `,
                {
                    closeButton: false
                }
            );
            marker.on("mouseover", () => {
                if (!marker.isPopupOpen()) {
                    marker.openPopup();
                }
            });
            marker.on("mouseout", () => {
                if (marker.isPopupOpen()) {
                    marker.closePopup();
                }
            });
            marker.on("click", () => {
                marker.closePopup();
                onLamppostClick(lamppostDict[d.lamppost_id]);
            });
            marker
                .getElement()
                .setAttribute(
                    "aria-label",
                    lamppostDisplayName(lamppostDict[d.lamppost_id])
                );
        });
    }, [map, rollingAverages, lamppostDict, pollutant]);

    useEffect(() => {
        if (
            !map ||
            rollingAverages.length === 0 ||
            Object.keys(lamppostDict).length === 0 ||
            isBoundFit
        ) {
            return;
        }
        const fitBounds = () => {
            const bounds = rollingAverages.map((d) => [
                lamppostDict[d.lamppost_id].latitude,
                lamppostDict[d.lamppost_id].longitude
            ]);
            bounds.length !== 0 && map.fitBounds(bounds);
        };
        if (!isBoundFit) {
            fitBounds();
            setIsBoundFit(true);
        }
    }, [map, rollingAverages, lamppostDict, isBoundFit]);

    return (
        <>
            <div className="mb-2 flexrow items-center flex-wrap">
                <span className="label">Pollutant:</span>
                <select
                    className="input-inline px-4 mr-12"
                    style={{ width: "180px" }}
                    value={pollutant}
                    onChange={(e) => {
                        setPollutant(e.target.value);
                    }}
                >
                    {Object.entries(POLLUTANTS).map(([p, n]) => (
                        <option key={p} value={p}>
                            {n}
                        </option>
                    ))}
                </select>
                {!isPlayingMode && (
                    <button
                        className="btn-secondary"
                        onClick={() => {
                            setIsPlayingMode(true);
                            setPaused(false);
                            onStartPlaying();
                        }}
                        disabled={disabled}
                    >
                        <div className="flexrow items-center">
                            <Icon
                                name={PlayFill}
                                width="16px"
                                cssClass="inline-block"
                            />
                            <span className="ml-1">
                                Show Historical Rolling Average on Map
                            </span>
                        </div>
                    </button>
                )}
                {isPlayingMode && (
                    <>
                        <button
                            className="btn-secondary mr-1"
                            onClick={() => {
                                if (paused) {
                                    setPaused(false);
                                    onResumePlaying();
                                } else {
                                    setPaused(true);
                                    onPausePlaying();
                                }
                            }}
                            disabled={disabled}
                        >
                            <div className="flexrow items-center">
                                <Icon
                                    name={PauseFill}
                                    width="16px"
                                    cssClass="inline-block"
                                />
                                <span className="ml-1">
                                    {paused ? "Resume" : "Pause"}
                                </span>
                            </div>
                        </button>
                        <button
                            className="btn-secondary mr-12"
                            onClick={() => {
                                setIsPlayingMode(false);
                                onStopPlaying();
                            }}
                            disabled={disabled}
                        >
                            <div className="flexrow items-center">
                                <Icon
                                    name={StopFill}
                                    width="16px"
                                    cssClass="inline-block"
                                />
                                <span className="ml-1">
                                    Show Latest Rolling Average
                                </span>
                            </div>
                        </button>
                        <span className="label">Rolling Average Time:</span>
                        {dataTime ? (
                            formatDate(dataTime, timeFormats.dayWithMin)
                        ) : (
                            <Loading
                                cssClass="inline-block text-gray-700"
                                word=""
                            />
                        )}
                    </>
                )}
            </div>
            <div className="map">
                <div ref={mapRef} id="mapid" height="100%" width="100%"></div>
            </div>
        </>
    );
};

RollingAverageMap.propTypes = {
    rollingAverages: PropTypes.array,
    lamppostDict: PropTypes.object,
    onLamppostClick: PropTypes.func.isRequired,
    onStartPlaying: PropTypes.func.isRequired,
    onPausePlaying: PropTypes.func.isRequired,
    onResumePlaying: PropTypes.func.isRequired,
    onStopPlaying: PropTypes.func.isRequired,
    dataTime: PropTypes.any,
    disabled: PropTypes.bool.isRequired
};
